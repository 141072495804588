<template>
  <base-material-card
    color="primary"
    icon="mdi-pencil"
    inline
    class="px-5 py-3"
  >
    <template v-slot:after-heading>
      <div class="display-1 font-weight-light">Informações Básicas</div>
    </template>
    <v-row
      align="center"
      no-gutters
      class="ml-0 pt-5"
    >
      <v-col cols="12">
        <validation-provider
          v-slot="{ errors }"
          rules="required"
          name="Nome do Mapa"
        >
          <v-text-field
            id="nome-mapa"
            label="Nome do Mapa"
            outlined
            :error-messages="errors"
            v-model="nomeCanvasLocal"
            maxlength="30"
            class="ml-4"
          />
        </validation-provider>
      </v-col>
    </v-row>
  </base-material-card>
</template>

<script>
export default {
  props: {
    nomeCanvas: {
      type: String,
      required: true
    }
  },

  computed: {
    nomeCanvasLocal: {
      get() {
        return this.nomeCanvas;
      },
      set(newValue) {
        this.$emit('update:nomeCanvas', newValue);
      }
    }
  }
};
</script>
